export async function get(path) {
  const response = await fetch(
    `${useRuntimeConfig().public.BACKEND_URL}${path}`,
    {
      credentials: "include",
    }
  );
  let responseText, json;

  try {
    responseText = await response.text();
    json = JSON.parse(responseText);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(responseText);

    throw error;
  }

  handleResponseErrors(json);

  return json;
}

export async function post(path, body = {}) {
  const response = await fetch(
    `${useRuntimeConfig().public.BACKEND_URL}${path}`,
    {
      method: "post",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  handleResponseErrors(json);

  return json;
}

export async function put(path, body = {}) {
  const response = await fetch(
    `${useRuntimeConfig().public.BACKEND_URL}${path}`,
    {
      method: "put",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  handleResponseErrors(json);

  return json;
}

export async function del(path, body = {}) {
  const response = await fetch(
    `${useRuntimeConfig().public.BACKEND_URL}${path}`,
    {
      method: "delete",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  handleResponseErrors(json);

  return json;
}

function handleResponseErrors(json) {
  if (json.errors && json.errors.length > 0) {
    if (
      json.errors.some((error) => error.status === "401") &&
      useRoute().path !== "/"
    ) {
      useRouter().push("/");
      return;
    }

    throw new Error(`${json.errors[0].status}: ${json.errors[0].detail}`);
  }
}

export default {
  get,
  post,
  put,
  del,
};
