import NameCollection from "./NameCollection";

const sortStates = Object.freeze({
  newest_first: "newest_first",
  oldest_first: "oldest_first",
  most_retweets_first: "most_retweets_first",
  least_retweets_first: "least_retweets_first",
  most_likes_first: "most_likes_first",
  least_likes_first: "least_likes_first",
});

export default new NameCollection(sortStates);
