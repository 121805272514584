import { useGtm } from "@gtm-support/vue-gtm";

export default {
  namespaced: true,

  state: {
    purchaseCategories: {
      subscriptionPurchase: "Subscription purchase",
      subscriptionUpgrade: "Subscription upgrade",
      featureUpgrade: "Feature upgrade",
    },
  },

  getters: {
    purchaseCategoryById: (state) => (id) => {
      if (!state.purchaseCategories[id])
        throw new Error("Purchase category ID doesn't exist");

      return state.purchaseCategories[id];
    },
  },

  actions: {
    TRACK_EVENT: (context, event) => {
      const gtm = useGtm();

      if (gtm && gtm.enabled()) {
        window.dataLayer?.push(event);
      }
    },
    TRACK_PURCHASE: (
      { getters, rootGetters },
      { id, name, categoryId, price, paymentType }
    ) => {
      const gtm = useGtm();
      const referralCode = window.localStorage.getItem("referralCode");
      const orderId = rootGetters.user?.relationships?.activeOrder?.data?.id;
      const currency = rootGetters.userCurrency;

      if (gtm.enabled() && orderId) {
        window.dataLayer?.push({
          event: "Purchase",
          ecommerce: {
            currencyCode: currency,
            purchase: {
              actionField: {
                id: orderId,
                affiliation: referralCode || "tweetdeleter.com",
                revenue: price,
                tax: "0.00",
                shipping: "0.00",
                coupon: "Not set",
              },
              products: [
                {
                  name,
                  id,
                  price,
                  brand: "TweetDeleter",
                  category: getters.purchaseCategoryById(categoryId),
                  variant: paymentType,
                  quantity: 1,
                },
              ],
            },
          },
        });
      }
    },
  },
};
