// ported from react-livechat https://github.com/livechat/react-livechat
export default {
  props: {
    // important
    license: {
      type: Number,
      required: true,
    },
    group: {
      type: Number,
      default: 0,
    },
    onChatLoaded: {
      type: Function,
      default: () => {},
    },
    // less important
    params: {
      type: Array,
      default: () => [],
    },
    visitor: {
      type: Object,
      default: () => ({}),
    },
    chatBetweenGroups: {
      type: Boolean,
      default: false,
    },
    onBeforeLoad: {
      type: Function,
      default: () => {},
    },
    onAfterLoad: {
      type: Function,
      default: () => {},
    },
    onChatWindowOpened: {
      type: Function,
      default: () => {},
    },
    onChatWindowMinimized: {
      type: Function,
      default: () => {},
    },
    onChatWindowHidden: {
      type: Function,
      default: () => {},
    },
    onChatStateChanged: {
      type: Function,
      default: () => {},
    },
    onChatStarted: {
      type: Function,
      default: () => {},
    },
    onChatEnded: {
      type: Function,
      default: () => {},
    },
    onMessage: {
      type: Function,
      default: () => {},
    },
    onTicketCreated: {
      type: Function,
      default: () => {},
    },
    onPrechatSurveySubmitted: {
      type: Function,
      default: () => {},
    },
    onPostchatSurveySubmitted: {
      type: Function,
      default: () => {},
    },
    onRatingSubmitted: {
      type: Function,
      default: () => {},
    },
    onRatingCommentSubmitted: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    this.loadLiveChatApi();
  },
  methods: {
    loadLiveChatApi() {
      if (!window.LC_API) {
        window.__lc = window.__lc || {};
        window.__lc.license = this.license;
        window.__lc.group = this.group;
        window.__lc.chat_between_groups = this.chatBetweenGroups;
        window.__lc.params = this.params;
        window.__lc.visitor = this.visitor;

        const lc = document.createElement("script");
        lc.type = "text/javascript";
        lc.async = true;
        lc.src =
          ("https:" === document.location.protocol ? "https://" : "http://") +
          "cdn.livechatinc.com/tracking.js";

        const s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(lc, s);

        lc.addEventListener("load", this.chatLoaded);
        lc.addEventListener("error", this.chatNotLoaded);
      }
    },
    chatLoaded() {
      if (window.LC_API) {
        this.setCallbacks();
        if (typeof this.onChatLoaded === "function") {
          this.onChatLoaded(window.LC_API);
        }
      }
    },
    chatNotLoaded() {
      if (typeof this.onErrorLoading === "function") {
        this.onErrorLoading();
      }
    },
    setCallbacks() {
      if (typeof this.onBeforeLoad === "function")
        window.LC_API.on_before_load = this.onBeforeLoad;

      if (typeof this.onAfterLoad === "function")
        window.LC_API.on_after_load = this.onAfterLoad;

      if (typeof this.onChatWindowOpened === "function")
        window.LC_API.on_chat_window_opened = this.onChatWindowOpened;

      if (typeof this.onChatWindowMinimized === "function")
        window.LC_API.on_chat_window_minimized = this.onChatWindowMinimized;

      if (typeof this.onChatWindowHidden === "function")
        window.LC_API.on_chat_window_hidden = this.onChatWindowHidden;

      if (typeof this.onChatStateChanged === "function")
        window.LC_API.on_chat_state_changed = this.onChatStateChanged;

      if (typeof this.onChatStarted === "function")
        window.LC_API.on_chat_started = this.onChatStarted;

      if (typeof this.onChatEnded === "function")
        window.LC_API.on_chat_ended = this.onChatEnded;

      if (typeof this.onMessage === "function")
        window.LC_API.on_message = this.onMessage;

      if (typeof this.onTicketCreated === "function")
        window.LC_API.on_ticket_created = this.onTicketCreated;

      if (typeof this.onPrechatSurveySubmitted === "function")
        window.LC_API.on_prechat_survey_submitted =
          this.onPrechatSurveySubmitted;

      if (typeof this.onRatingSubmitted === "function")
        window.LC_API.on_rating_submitted = this.onRatingSubmitted;

      if (typeof this.onRatingCommentSubmitted === "function")
        window.LC_API.on_rating_comment_submitted =
          this.onRatingCommentSubmitted;
    },
  },
  render(h) {
    return h(false);
  },
};
