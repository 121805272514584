<template>
  <div class="flex justify-center items-center">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="animate-spin"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C9.22656 24 6.52148 23.0317 4.3833 21.2734C3.95703 20.9224 3.89551 20.2925 4.24609 19.8657C4.59717 19.4399 5.22803 19.3784 5.65381 19.7285C7.43506 21.1934 9.68896 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48584 17.5142 2 12 2C6.48584 2 2 6.48584 2 12C2 13.4248 2.29346 14.8013 2.87207 16.0908C3.09814 16.5947 2.87256 17.1865 2.36865 17.4126C1.86328 17.6372 1.27295 17.4126 1.04688 16.9092C0.352051 15.3608 0 13.709 0 12C0 5.3833 5.3833 0 12 0C18.6167 0 24 5.3833 24 12C24 18.6167 18.6167 24 12 24Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: "currentColor",
    },
  },
};
</script>
