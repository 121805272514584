export default {
  namespaced: true,

  state: {
    pathMessages: null,
  },

  mutations: {
    SET_PATH_MESSAGES: (state, messages) => {
      state.pathMessages = messages;
    },
  },
};
