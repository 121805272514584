import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { FilterNames } from "~/resources/tweetFilterNames";
import SortStates from "~/resources/tweetSortStates";

import analytics from "./modules/analytics";
import i18n from "./modules/i18n";

export default new Vuex.Store({
  modules: {
    analytics,
    i18n,
  },
  state: {
    status: null,
    session: null,
    user: null,
    dontShowDeleteConfirmation: null,
    isSessionAfterLogin: false,
    userFetchTimestamp: Date.now(),
    // refresh user data at least once per hour
    userStaleThreshold: 60 * 60 * 1000,
    cookieConsentGranted: null,
    features: null,
    loadingOffers: false,
    offers: null,
    errors: [],
    firstLoadDone: false,
    uploadArchiveCheckoutFlowStarted: false,
    archiveUploading: false,
    archiveDirectUploadProgressValues: [],
    uploadedArchiveData: [],
    jsFileUploadFlowStarted: false,
    showPreDashboard: false,
    preDashboardShown: false,
    // array of local tweet data (e.g. of whether a tweet is selected)
    tweetState: [],
    loadingTweets: false,
    reloadingTweets: false,
    lastTweetFetchOptionsString: "",
    loadStoredTweets: false,
    tweetMetadata: null,
    tweets: [],
    tweetSearchFilters: [],
    pillFilterNames: [
      FilterNames.tweet_type,
      FilterNames.date,
      FilterNames.profanities,
      FilterNames.time,
      FilterNames.weekday,
      FilterNames.media,
    ],
    tweetSelectionState: "none", // "all" | "shown" | "none"
    tweetSortState: SortStates.get("newest_first"),
    // array of local favorites' data (e.g. of whether some are selected)
    archiveFavoriteState: [],
    archiveFavoriteMetadata: null,
    archiveFavorites: [],
    archiveFavoriteAllowedFilterNames: [FilterNames.keyword],
    autoDeletionRulesLoaded: false,
    autoDeletionRules: [],
    userFeatureUpdateRequired: false,
    userFeatureRawResponses: [],
    userFeatureLimitStatuses: [],
    featureLimitExceededPopupData: {
      show: false,
      featureAction: null,
      planId: null,
    },
    packages: [],
    upgradePopupData: {
      show: false,
      upgradeType: null, // "plan" or "feature"
      itemId: null, // offer or feature id
    },
    featureCategories: {
      smart_search_tweets: [
        "archive_upload",
        "keyword_search",
        "media_search",
        "date_search",
        "type_search",
        "has_rude_word",
      ],
      delete_tweets: [
        "delete_tweets",
        "delete_all_tweets",
        "unlike_all_tweets",
        "deleted_tweets_store",
      ],
      auto_delete_tweets: ["auto_delete"],
    },
  },
  mutations,
  actions,
  getters,
});
