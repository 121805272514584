export default {
  computed: {
    $_pageName() {
      return this.$route.meta.cmsName;
    },
    location() {
      return useRuntimeConfig().public.BASE_URL + this.$route.path;
    },
    title() {
      return this.$te(`page.${this.$_pageName}.meta.title`)
        ? this.$t(`page.${this.$_pageName}.meta.title`)
        : this.$t(`global.fallback_meta.title`);
    },
    description() {
      return this.$te(`page.${this.$_pageName}.meta.description`)
        ? this.$t(`page.${this.$_pageName}.meta.description`)
        : this.$t(`global.fallback_meta.description`);
    },
    ogShareImg() {
      return this.$te(`page.${this.$_pageName}.meta.og_share_img.src`)
        ? this.$t(`page.${this.$_pageName}.meta.og_share_img.src`)
        : this.$t(`global.fallback_meta.og_share_img.src`);
    },
    twitterShareImg() {
      return this.$te(`page.${this.$_pageName}.meta.twitter_share_img.src`)
        ? this.$t(`page.${this.$_pageName}.meta.twitter_share_img.src`)
        : this.$t(`global.fallback_meta.twitter_share_img.src`);
    },
  },
  watch: {
    $route() {
      this.setMeta();
    },
    $_pageName() {
      this.setMeta();
    }
  },
  created() {
    this.setMeta();
  },
  mounted() {
    this.setMeta();
  },
  methods: {
    setMeta() {
      useHead({
        title: this.title,
        meta: [
          {
            key: "description",
            name: "description",
            content: this.description,
          },
          {
            key: "og:title",
            property: "og:title",
            content: this.title,
          },
          {
            key: "og:url",
            property: "og:url",
            content: this.location,
          },
          {
            key: "og:description",
            name: "og:description",
            content: this.description,
          },
          {
            key: "og:image",
            property: "og:image",
            content: useRuntimeConfig().public.BASE_URL + this.ogShareImg,
          },
          {
            key: "twitter:title",
            name: "twitter:title",
            content: this.title,
          },
          {
            key: "twitter:description",
            name: "twitter:description",
            content: this.description,
          },
          {
            key: "twitter:image",
            property: "twitter:image",
            content: useRuntimeConfig().public.BASE_URL + this.twitterShareImg,
          },
        ],
      });
    },
  },
};
