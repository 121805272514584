<template>
  <div>
    <section class="py-16">
      <img
        src="@/assets/img/tweetdeleter-logo.svg"
        alt="Tweetdeleter logo"
        width="96"
        height="48"
      />
    </section>
    <section class="mt-32 md:mt-64 pb-64">
      <div class="flex flex-col lg:flex-row">
        <div class="flex-1 text-left mx-auto lg:pt-32 lg:mx-0">
          <h1 class="hero text-base-30">Oops! Something went wrong</h1>
          <h1 class="hero">We are working to fix it as soon as possible.</h1>
          <p class="text--intro mt-32">
            Come back soon and enjoy our features.
          </p>
        </div>
        <div class="flex-none flex flex-col items-center lg:mr-88">
          <img
            src="@/assets/img/website/skull-sweaty.svg"
            alt="Skull sweaty illustration"
            width="432"
            height="432"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";
import { captureException } from "@sentry/nuxt";

const props = defineProps({
  error: Object as () => NuxtError,
});

captureException(props.error);
console.log(toRaw(props.error));
</script>
