import { default as about_45us9kiw2OpfgnMeta } from "/opt/build/repo/pages/about-us.vue?macro=true";
import { default as _91slug_932BH3UhQjmqMeta } from "/opt/build/repo/pages/blog/[slug].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as _91planId_934tCJOGeDAQMeta } from "/opt/build/repo/pages/checkout/[planId].vue?macro=true";
import { default as thank_45youbo5aY4j4EWMeta } from "/opt/build/repo/pages/checkout/thank-you.vue?macro=true";
import { default as contact_45usM8CpKpYLVzMeta } from "/opt/build/repo/pages/contact-us.vue?macro=true";
import { default as _91_91pageId_93_93sKbwag7UKlMeta } from "/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue?macro=true";
import { default as deletion_45startedCUnx0ZVM0uMeta } from "/opt/build/repo/pages/dashboard/deletion-started.vue?macro=true";
import { default as indexViWjQzLqkmMeta } from "/opt/build/repo/pages/dashboard/index.vue?macro=true";
import { default as search_45and_45deletenq81tSJlUdMeta } from "/opt/build/repo/pages/dashboard/search-and-delete.vue?macro=true";
import { default as see_45deleted_45tweetsbhSAFWhSL4Meta } from "/opt/build/repo/pages/dashboard/see-deleted-tweets.vue?macro=true";
import { default as _91_91ruleId_93_93rqsEluFXKoMeta } from "/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue?macro=true";
import { default as tasksXoPRaHtXwPMeta } from "/opt/build/repo/pages/dashboard/tasks.vue?macro=true";
import { default as faqKBZt61hnJ8Meta } from "/opt/build/repo/pages/faq.vue?macro=true";
import { default as _91featureSlug_93jYf3ubGh5aMeta } from "/opt/build/repo/pages/features/[featureSlug].vue?macro=true";
import { default as indexKrctpGCWF5Meta } from "/opt/build/repo/pages/features/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as pricing2lLggLVj9TMeta } from "/opt/build/repo/pages/pricing.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as terms_45of_45serviceIo0hlUNnc4Meta } from "/opt/build/repo/pages/terms-of-service.vue?macro=true";
import { default as _91_91pageId_93_935Ac5F7wB8xMeta } from "/opt/build/repo/pages/upload-archive/[[pageId]].vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us___en",
    path: "/about-us",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___es",
    path: "/es/conocenos",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___ja",
    path: "/ja/tweetdeleter-ni-tsuite",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___pt",
    path: "/pt/sobre-nos",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___ar",
    path: "/ar/nabtha-aana",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___fr",
    path: "/fr/qui-nous-sommes",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___de",
    path: "/de/ueber-uns",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___tr",
    path: "/tr/hakkimizda",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___hi",
    path: "/hi/hamaare-baare-mein",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___ko",
    path: "/ko/ulie-daehae",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___ru",
    path: "/ru/o-nas",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___tl",
    path: "/tl/tungkol-sa-amin",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___th",
    path: "/th/keiyw-kab-rea",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "about-us___ms",
    path: "/ms/perihal-kami",
    meta: about_45us9kiw2OpfgnMeta || {},
    component: () => import("/opt/build/repo/pages/about-us.vue")
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___ja",
    path: "/ja/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___pt",
    path: "/pt/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___ar",
    path: "/ar/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___tr",
    path: "/tr/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___hi",
    path: "/hi/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___ko",
    path: "/ko/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___ru",
    path: "/ru/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___tl",
    path: "/tl/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___th",
    path: "/th/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___ms",
    path: "/ms/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___ja",
    path: "/ja/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___pt",
    path: "/pt/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___ar",
    path: "/ar/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___tr",
    path: "/tr/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___hi",
    path: "/hi/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___ko",
    path: "/ko/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___tl",
    path: "/tl/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___th",
    path: "/th/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "blog___ms",
    path: "/ms/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "checkout-planId___en",
    path: "/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___es",
    path: "/es/comprar/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___ja",
    path: "/ja/chekkuauto/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___pt",
    path: "/pt/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___ar",
    path: "/ar/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___fr",
    path: "/fr/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___de",
    path: "/de/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___tr",
    path: "/tr/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___hi",
    path: "/hi/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___ko",
    path: "/ko/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___ru",
    path: "/ru/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___tl",
    path: "/tl/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___th",
    path: "/th/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-planId___ms",
    path: "/ms/checkout/:planId()",
    meta: _91planId_934tCJOGeDAQMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/[planId].vue")
  },
  {
    name: "checkout-thank-you___en",
    path: "/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___es",
    path: "/es/comprar/gracias",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___ja",
    path: "/ja/chekkuauto/arigatogozaimasu",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___pt",
    path: "/pt/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___ar",
    path: "/ar/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___fr",
    path: "/fr/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___de",
    path: "/de/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___tr",
    path: "/tr/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___hi",
    path: "/hi/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___ko",
    path: "/ko/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___ru",
    path: "/ru/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___tl",
    path: "/tl/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___th",
    path: "/th/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___ms",
    path: "/ms/checkout/thank-you",
    meta: thank_45youbo5aY4j4EWMeta || {},
    component: () => import("/opt/build/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___es",
    path: "/es/contacto",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___ja",
    path: "/ja/o-toiawase",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___pt",
    path: "/pt/fala-connosco",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___ar",
    path: "/ar/etasil-bina",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___fr",
    path: "/fr/nous-contacter",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___de",
    path: "/de/kontakt",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___tr",
    path: "/tr/iletisim",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___hi",
    path: "/hi/hamase-sampark-karen",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___ko",
    path: "/ko/mun-uihagi",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___ru",
    path: "/ru/obratitsya-k-nam",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___tl",
    path: "/tl/kontakin-kami",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___th",
    path: "/th/tidtx-rea",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___ms",
    path: "/ms/hubungi-kami",
    meta: contact_45usM8CpKpYLVzMeta || {},
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "dashboard-delete-all-pageId___en",
    path: "/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___es",
    path: "/es/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___ja",
    path: "/ja/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___pt",
    path: "/pt/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___ar",
    path: "/ar/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___fr",
    path: "/fr/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___de",
    path: "/de/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___tr",
    path: "/tr/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___hi",
    path: "/hi/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___ko",
    path: "/ko/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___ru",
    path: "/ru/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___tl",
    path: "/tl/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___th",
    path: "/th/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-delete-all-pageId___ms",
    path: "/ms/dashboard/delete-all/:pageId?",
    meta: _91_91pageId_93_93sKbwag7UKlMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/delete-all/[[pageId]].vue")
  },
  {
    name: "dashboard-deletion-started___en",
    path: "/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___es",
    path: "/es/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___ja",
    path: "/ja/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___pt",
    path: "/pt/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___ar",
    path: "/ar/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___fr",
    path: "/fr/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___de",
    path: "/de/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___tr",
    path: "/tr/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___hi",
    path: "/hi/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___ko",
    path: "/ko/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___ru",
    path: "/ru/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___tl",
    path: "/tl/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___th",
    path: "/th/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard-deletion-started___ms",
    path: "/ms/dashboard/deletion-started",
    meta: deletion_45startedCUnx0ZVM0uMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/deletion-started.vue")
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___es",
    path: "/es/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___ja",
    path: "/ja/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___pt",
    path: "/pt/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___ar",
    path: "/ar/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___fr",
    path: "/fr/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___de",
    path: "/de/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___tr",
    path: "/tr/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___hi",
    path: "/hi/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___ko",
    path: "/ko/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___ru",
    path: "/ru/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___tl",
    path: "/tl/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___th",
    path: "/th/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___ms",
    path: "/ms/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-search-and-delete___en",
    path: "/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___es",
    path: "/es/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___ja",
    path: "/ja/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___pt",
    path: "/pt/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___ar",
    path: "/ar/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___fr",
    path: "/fr/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___de",
    path: "/de/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___tr",
    path: "/tr/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___hi",
    path: "/hi/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___ko",
    path: "/ko/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___ru",
    path: "/ru/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___tl",
    path: "/tl/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___th",
    path: "/th/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-search-and-delete___ms",
    path: "/ms/dashboard/search-and-delete",
    meta: search_45and_45deletenq81tSJlUdMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/search-and-delete.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___en",
    path: "/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___es",
    path: "/es/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___ja",
    path: "/ja/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___pt",
    path: "/pt/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___ar",
    path: "/ar/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___fr",
    path: "/fr/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___de",
    path: "/de/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___tr",
    path: "/tr/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___hi",
    path: "/hi/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___ko",
    path: "/ko/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___ru",
    path: "/ru/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___tl",
    path: "/tl/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___th",
    path: "/th/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-see-deleted-tweets___ms",
    path: "/ms/dashboard/see-deleted-tweets",
    meta: see_45deleted_45tweetsbhSAFWhSL4Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard/see-deleted-tweets.vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___en",
    path: "/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___es",
    path: "/es/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___ja",
    path: "/ja/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___pt",
    path: "/pt/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___ar",
    path: "/ar/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___fr",
    path: "/fr/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___de",
    path: "/de/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___tr",
    path: "/tr/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___hi",
    path: "/hi/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___ko",
    path: "/ko/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___ru",
    path: "/ru/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___tl",
    path: "/tl/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___th",
    path: "/th/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-set-up-auto-pageId-ruleId___ms",
    path: "/ms/dashboard/set-up-auto/:pageId?/:ruleId?",
    meta: _91_91ruleId_93_93rqsEluFXKoMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/set-up-auto/[[pageId]]/[[ruleId]].vue")
  },
  {
    name: "dashboard-tasks___en",
    path: "/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___es",
    path: "/es/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___ja",
    path: "/ja/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___pt",
    path: "/pt/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___ar",
    path: "/ar/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___fr",
    path: "/fr/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___de",
    path: "/de/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___tr",
    path: "/tr/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___hi",
    path: "/hi/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___ko",
    path: "/ko/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___ru",
    path: "/ru/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___tl",
    path: "/tl/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___th",
    path: "/th/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-tasks___ms",
    path: "/ms/dashboard/tasks",
    meta: tasksXoPRaHtXwPMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard/tasks.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___es",
    path: "/es/preguntas-frecuentes",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___ja",
    path: "/ja/yoku-aru-go-shitsumon",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___pt",
    path: "/pt/perguntas-frequentes",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___ar",
    path: "/ar/al-asila-al-mutadawala",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/haeufig-gestellte-fragen",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___tr",
    path: "/tr/sss",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___hi",
    path: "/hi/praay-poochhe-jaane-vaale-prashna",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___ko",
    path: "/ko/jaju-mudneun-jilmun",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/voprosi-i-otveti",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___tl",
    path: "/tl/faq",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___th",
    path: "/th/khatham-thi-phb-bxy",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "faq___ms",
    path: "/ms/soalan-lazim",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "features-featureSlug___en",
    path: "/features/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___es",
    path: "/es/funciones/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___ja",
    path: "/ja/kino/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___pt",
    path: "/pt/funcionalidades/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___ar",
    path: "/ar/almizat/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___fr",
    path: "/fr/fonctionnalites/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___de",
    path: "/de/funktionen/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___tr",
    path: "/tr/ozellikler/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___hi",
    path: "/hi/suvidhaen/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___ko",
    path: "/ko/gineung/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___ru",
    path: "/ru/funkcii/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___tl",
    path: "/tl/mga-feature/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___th",
    path: "/th/khunsmbati/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features-featureSlug___ms",
    path: "/ms/ciri-ciri/:featureSlug()",
    meta: _91featureSlug_93jYf3ubGh5aMeta || {},
    component: () => import("/opt/build/repo/pages/features/[featureSlug].vue")
  },
  {
    name: "features___en",
    path: "/features",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___es",
    path: "/es/funciones",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___ja",
    path: "/ja/kino",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___pt",
    path: "/pt/funcionalidades",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___ar",
    path: "/ar/al-mayeezat",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___fr",
    path: "/fr/fonctionnalites",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___de",
    path: "/de/funktionen",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___tr",
    path: "/tr/ozellikler",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___hi",
    path: "/hi/suvidhaen",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___ko",
    path: "/ko/gineung",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___ru",
    path: "/ru/funkcii",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___tl",
    path: "/tl/mga-feature",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___th",
    path: "/th/khunsmbati",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "features___ms",
    path: "/ms/ciri-ciri",
    meta: indexKrctpGCWF5Meta || {},
    component: () => import("/opt/build/repo/pages/features/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/ja",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/ar",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/tr",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___hi",
    path: "/hi",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___ko",
    path: "/ko",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___tl",
    path: "/tl",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___th",
    path: "/th",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___ms",
    path: "/ms",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "pricing___en",
    path: "/pricing",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___es",
    path: "/es/precios",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___ja",
    path: "/ja/ryokin-puran",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___pt",
    path: "/pt/preco",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___ar",
    path: "/ar/al-asaar",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___fr",
    path: "/fr/prix",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___de",
    path: "/de/preise",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___tr",
    path: "/tr/fiyatlandirma",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___hi",
    path: "/hi/keemat",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___ko",
    path: "/ko/hoebi",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___ru",
    path: "/ru/ceni",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___tl",
    path: "/tl/presyo",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___th",
    path: "/th/rakha",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "pricing___ms",
    path: "/ms/harga",
    meta: pricing2lLggLVj9TMeta || {},
    component: () => import("/opt/build/repo/pages/pricing.vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___es",
    path: "/es/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___ja",
    path: "/ja/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___pt",
    path: "/pt/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___ar",
    path: "/ar/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___fr",
    path: "/fr/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___de",
    path: "/de/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___tr",
    path: "/tr/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___hi",
    path: "/hi/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___ko",
    path: "/ko/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___ru",
    path: "/ru/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___tl",
    path: "/tl/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___th",
    path: "/th/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "profile___ms",
    path: "/ms/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue")
  },
  {
    name: "terms-of-service___en",
    path: "/terms-of-service",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___es",
    path: "/es/condiciones-del-servicio",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___ja",
    path: "/ja/sabisu-riyo-kiyaku",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___pt",
    path: "/pt/termos-do-servico",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___ar",
    path: "/ar/shuroot-al-istikhdam",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___fr",
    path: "/fr/conditions-dutilisation",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___de",
    path: "/de/nutzungsbedingungen",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___tr",
    path: "/tr/kullanim-sartlari",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___hi",
    path: "/hi/seva-kee-sharten",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___ko",
    path: "/ko/seobiseu-yaggwan",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___ru",
    path: "/ru/usloviya-ispolzovaniya",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___tl",
    path: "/tl/mga-tuntunin-ng-serbisyo",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___th",
    path: "/th/ngeuxnkhi-kar-hibrikar",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___ms",
    path: "/ms/terma-perkhidmatan",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    component: () => import("/opt/build/repo/pages/terms-of-service.vue")
  },
  {
    name: "upload-archive-pageId___en",
    path: "/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___es",
    path: "/es/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___ja",
    path: "/ja/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___pt",
    path: "/pt/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___ar",
    path: "/ar/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___fr",
    path: "/fr/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___de",
    path: "/de/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___tr",
    path: "/tr/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___hi",
    path: "/hi/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___ko",
    path: "/ko/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___ru",
    path: "/ru/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___tl",
    path: "/tl/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___th",
    path: "/th/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: "upload-archive-pageId___ms",
    path: "/ms/upload-archive/:pageId?",
    meta: _91_91pageId_93_935Ac5F7wB8xMeta || {},
    component: () => import("/opt/build/repo/pages/upload-archive/[[pageId]].vue")
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/about",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/about/faq",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/tweets",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/tweets/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/about/terms-of-service",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/archives",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/auto_delete",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/blog",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/blog/21",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/blog/21/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/blog/18",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/blog/18/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/2",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/2/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/3",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/3/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/4",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/4/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/5",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/5/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/9",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/9/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/features/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/offers",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/offers/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/about",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/about/faq",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/about/terms-of-service",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/archives",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/auto_delete",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/blog/21",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/blog/21/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/blog/18",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/blog/18/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/2",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/2/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/3",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/3/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/4",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/4/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/5",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/5/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/9",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/9/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/features/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/offers",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/home",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/HVZ",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ja/messages/new",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/about",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/about/faq",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/about/terms-of-service",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/archives",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/auto_delete",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/blog/21",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/blog/21/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/blog/18",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/blog/18/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/2",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/2/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/3",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/3/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/4",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/4/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/5",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/5/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/9",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/9/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/features/:pathMatch(.*)",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/offers",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/home",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/HVZ",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/es/messages/new",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/blog/managing-sensitive-content-on-x-twitter",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/blog/exploring-twitter-without-an-account-access-public-x",
    component: component_45stubRU5sOBTecH
  }
]