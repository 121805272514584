import {
  getStatus,
  getSession,
  getUser,
  getFeatures,
  getOffers,
  orders,
  archives,
  tweets,
  archiveFavorites,
  storedTweets,
  autoDeletionRules,
  getUserFeatures,
  getPackages,
  stopPackage,
  grantDiscount,
  updateUserCountry,
} from "@/api";
import FeatureNames from "~/resources/featureActionNames";

export default {
  INIT: ({ commit }) => {
    const cookieConsentGranted = window.localStorage.getItem("cookieConsentGranted") === "true";
    const dontShowDeleteConfirmation = window.localStorage.getItem("dontShowDeleteConfirmation") === "true";

    commit("GRANT_COOKIE_CONSENT", cookieConsentGranted);
    commit("SET_DONT_SHOW_DELETE_CONFIRMATION", dontShowDeleteConfirmation);
    commit("SET_FIRST_LOAD_DONE", true);
  },
  GET_STATUS: ({ commit, state }) => {
    return state.status
      ? Promise.resolve(state.status)
      : getStatus().then((status) => commit("SET_STATUS", { status }));
  },
  GET_SESSION: ({ dispatch, commit, state }, { forceUpdate } = {}) => {
    return state.session && !forceUpdate
      ? Promise.resolve(state.session)
      : getSession().then((session) => {
          commit("SET_SESSION", { session });

          if (session.data.attributes.authenticated)
            return dispatch("GET_USER");
        });
  },
  GET_USER: ({ commit, state }, { forceUpdate } = {}) => {
    return state.user && !forceUpdate
      ? Promise.resolve(state.user)
      : getUser().then((user) => {
          state.userFetchTimestamp = Date.now();

          if (!user || !user.data) {
            commit("SET_USER", { user: null });
            return;
          }
          
          commit("SET_USER", { user });

          const userFeatures = user.data.relationships.usersFeatures.data.map(
            (relationship) =>
              user.included.find(
                (included) =>
                  included.id === relationship.id &&
                  included.type === relationship.type
              )
          );
          commit("ADD_USER_FEATURES_RAW_RESPONSE", userFeatures);
        });
  },
  UPDATE_USER_COUNTRY: async ({ dispatch }, { countryName }) => {
    return updateUserCountry({ countryName }).then(() =>
      dispatch("GET_USER", { forceUpdate: true })
    );
  },
  GET_FEATURES: ({ commit, state }) => {
    return state.features
      ? Promise.resolve(state.features)
      : getFeatures().then((features) => commit("SET_FEATURES", { features }));
  },
  GET_OFFERS: ({ commit, state }, { forceUpdate } = {}) => {
    if (state.offers && !forceUpdate) return Promise.resolve(state.offers);
    else {
      state.loadingOffers = true;

      return getOffers().then((offers) => {
        commit("SET_OFFERS", { offers });
        state.loadingOffers = false;
      });
    }
  },
  GET_TWEETS: async (
    { commit, getters, state, dispatch },
    { forceUpdate } = {}
  ) => {
    const options = {
      ...getters.tweetSortOptions,
      ...getters.tweetSearchFilterOptions(),
      timezone_offset: new Date().getTimezoneOffset(),
    };

    const apiEndpoint = state.loadStoredTweets ? tweets.getStored : tweets.get;

    if (getters.isUserStale(Date.now()))
      await dispatch("GET_USER", { forceUpdate: true });

    await dispatch("ATTEMPT_TWEET_AND_FAVORITE_RELOAD");

    // don't repeat the same request twice in a row
    // but allow retries if no tweets found
    if (
      state.loadingTweets ||
      (state.lastTweetFetchOptionsString === JSON.stringify(options) &&
        state.tweets.length &&
        !forceUpdate)
    ) {
      return;
    }

    dispatch("TOGGLE_ALL_TWEETS_SELECTED", { selected: false });
    state.loadingTweets = true;
    state.lastTweetFetchOptionsString = JSON.stringify(options);

    if (getters.fetchTweets) {
      const tweetResponse = await apiEndpoint(options);

      commit("SET_TWEET_DATA", {
        metadata: tweetResponse.data.attributes,
        tweets: tweetResponse.included,
      });
    } else {
      commit("SET_TWEET_DATA", {
        metadata: {},
        tweets: [],
      });
    }

    state.loadingTweets = false;

    dispatch("GET_USER_FEATURES");
    if (getters.fetchArchiveFavorites) dispatch("GET_ARCHIVE_FAVORITES");
  },
  GRANT_DISCOUNT: async ({ dispatch }, { discountType }) => {
    return grantDiscount(discountType).then(() =>
      dispatch("GET_OFFERS", { forceUpdate: true })
    );
  },
  LOAD_MORE_TWEETS: ({ commit, getters, state, dispatch }) => {
    if (getters.allTweetsLoaded && getters.fetchArchiveFavorites) {
      dispatch("LOAD_MORE_ARCHIVE_FAVORITES");
      return;
    }
    if (!state.tweets.length) return;

    const apiEndpoint = state.loadStoredTweets ? tweets.getStored : tweets.get;

    state.loadingTweets = true;

    const options = {
      offset: state.tweets.length,
      ...getters.tweetSortOptions,
      ...getters.tweetSearchFilterOptions(),
      timezone_offset: new Date().getTimezoneOffset(),
    };

    return apiEndpoint(options).then((tweets) => {
      commit("SET_TWEET_DATA", {
        metadata: tweets.data.attributes,
        tweets: state.tweets.concat(tweets.included),
      });

      state.loadingTweets = false;

      dispatch("GET_USER_FEATURES");
    });
  },
  DELETE_TWEETS: async ({ getters, dispatch }) => {
    if (getters.tweetSelectionState === "shown")
      await Promise.all([
        dispatch("DELETE_TWEETS_BY_IDS"),
        dispatch("DELETE_ARCHIVE_FAVORITES_BY_IDS"),
      ]);
    else if (
      getters.selectedTweetCount === getters.totalTweetCount &&
      getters.tweetSelectionState === "all"
    )
      await Promise.all([
        dispatch("DELETE_TWEETS_BY_FILTER"),
        dispatch("DELETE_ARCHIVE_FAVORITES_BY_FILTER"),
      ]);

    await dispatch("GET_USER_FEATURES");
  },
  DELETE_TWEETS_BY_FILTER: ({ state, getters, dispatch }) => {
    // don't delete tweets if they are not currently being fetched
    if (!getters.fetchTweets) return;

    const options = getters.tweetSearchFilterOptions();

    return tweets.deleteByFilter(options).then(() => {
      state.lastTweetFetchOptionsString = "";

      return dispatch("GET_TWEETS");
    });
  },
  DELETE_TWEETS_BY_IDS: ({ state, getters, dispatch }) => {
    const tweetIds = getters.selectedTweetIds;

    if (!tweetIds.length) return;

    return tweets.deleteByIds({ tweetIds }).then(() => {
      state.lastTweetFetchOptionsString = "";

      return dispatch("GET_TWEETS");
    });
  },
  GET_ARCHIVE_FAVORITES: async ({ commit, getters, state, dispatch }) => {
    if (
      !getters.userHasAccessToArchiveFavorites &&
      !getters.fetchArchiveFavorites
    ) {
      commit("SET_ARCHIVE_FAVORITES_DATA", {
        metadata: {},
        tweets: [],
      });
      return;
    }

    const options = {
      ...getters.archiveFavoriteSortOptions,
      ...getters.archiveFavoriteFilterOptions,
    };

    dispatch("TOGGLE_ALL_TWEETS_SELECTED", { selected: false });
    state.loadingTweets = true;

    try {
      const tweetResponse = await archiveFavorites.get(options);

      commit("SET_ARCHIVE_FAVORITES_DATA", {
        metadata: tweetResponse.data.attributes,
        tweets: tweetResponse.included,
      });
  
      state.loadingTweets = false;
    } catch (error) {
      state.loadingTweets = false;
      throw error;
    }
    
  },
  LOAD_MORE_ARCHIVE_FAVORITES: ({ commit, getters, state, dispatch }) => {
    if (
      !state.archiveFavorites.length ||
      !getters.userHasAccessToArchiveFavorites
    )
      return;

    state.loadingTweets = true;

    const options = {
      offset: state.archiveFavorites.length,
      ...getters.archiveFavoriteSortOptions,
      ...getters.archiveFavoriteFilterOptions,
    };

    return archiveFavorites.get(options).then((tweets) => {
      commit("SET_ARCHIVE_FAVORITES_DATA", {
        metadata: tweets.data.attributes,
        tweets: state.archiveFavorites.concat(tweets.included),
      });

      state.loadingTweets = false;

      dispatch("GET_USER_FEATURES");
    }).catch(error => {
      state.loadingTweets = false;
      throw error;
    });
  },
  DELETE_ARCHIVE_FAVORITES_BY_FILTER: ({ getters, dispatch }) => {
    if (!getters.fetchArchiveFavorites) return;

    const options = getters.archiveFavoriteFilterOptions;

    return archiveFavorites.deleteByFilter(options).then(() => {
      return dispatch("GET_ARCHIVE_FAVORITES");
    });
  },
  DELETE_ARCHIVE_FAVORITES_BY_IDS: ({ getters, dispatch }) => {
    const tweetIds = getters.selectedArchiveFavoriteIds;

    if (!getters.userHasAccessToArchiveFavorites || !tweetIds.length) return;

    return archiveFavorites.deleteByIds({ tweetIds }).then(() => {
      return dispatch("GET_ARCHIVE_FAVORITES");
    });
  },
  RELOAD_TWITTER_TWEETS: ({ dispatch }) => {
    return tweets
      .reloadTwitterTweets()
      .then(() => dispatch("GET_SESSION", { forceUpdate: true }))
      .then(() => dispatch("GET_USER", { forceUpdate: true }));
  },
  RELOAD_TWITTER_FAVORITES: ({ dispatch }) => {
    return tweets
      .reloadTwitterFavorites()
      .then(() => dispatch("GET_SESSION", { forceUpdate: true }))
      .then(() => dispatch("GET_USER", { forceUpdate: true }));
  },
  ATTEMPT_TWEET_AND_FAVORITE_RELOAD: async ({ state, getters, dispatch }) => {
    if (!getters.user || !getters.userAuthenticated) return;

    state.reloadingTweets = true;
  
    const tweetsReloadRequired = getters.user.attributes.tweetsReloadRequired;
    const favoritesReloadRequired =
      getters.user.attributes.favoritesReloadRequired;
    const userHasAccessToFavoritesReload = getters.userHasAccessToFeature(
      FeatureNames.get("unlike_tweets")
    );

    try {
      if (tweetsReloadRequired) await dispatch("RELOAD_TWITTER_TWEETS");
      if (favoritesReloadRequired && userHasAccessToFavoritesReload)
        await dispatch("RELOAD_TWITTER_FAVORITES");
    } catch (error) {
      state.reloadingTweets = false;

      throw error;
    }

    state.reloadingTweets = false;
  },
  UPGRADE_ORDER: ({ dispatch }, { offerId }) => {
    return orders
      .upgrade({ offerId })
      .then(() => dispatch("GET_USER", { forceUpdate: true }));
  },
  CANCEL_ORDER: ({ dispatch }) => {
    return orders
      .cancel()
      .then(() => dispatch("GET_USER", { forceUpdate: true }));
  },
  APPLY_CANCELATION_DISCOUNT: ({ dispatch }) => {
    return orders
      .applyCancelationDiscount()
      .then(() => dispatch("GET_USER", { forceUpdate: true }));
  },
  ENABLE_STORED_TWEETS: ({ dispatch }) => {
    return storedTweets
      .enable()
      .then(() => dispatch("GET_USER", { forceUpdate: true }));
  },
  DISABLE_STORED_TWEETS: ({ dispatch }) => {
    return storedTweets
      .disable()
      .then(() => dispatch("GET_USER", { forceUpdate: true }));
  },
  UPLOAD_ARCHIVE_FILES: ({ commit }, { signedBlobIds }) => {
    return archives
      .upload({ archive_files: signedBlobIds })
      .then((archiveResponse) =>
        commit("ADD_UPLOADED_ARCHIVE_DATA", {
          archiveData: archiveResponse.data,
        })
      );
  },
  UPDATE_ARCHIVE_STATUS: ({ commit, state }) => {
    return Promise.all(
      state.uploadedArchiveData.map((archiveData) =>
        archives.status({ archiveId: archiveData.id })
      )
    ).then((statusResponses) =>
      commit("SET_UPLOADED_ARCHIVE_DATA", {
        archiveData: statusResponses.map((response) => response.data),
      })
    );
  },
  GET_AUTO_DELETION_RULES: ({ state, commit }) => {
    return autoDeletionRules
      .get()
      .then((response) => commit("SET_AUTO_DELETION_RULES", response.data))
      .finally(() => {
        state.autoDeletionRulesLoaded = true;
      });
  },
  CREATE_AUTO_DELETION_RULE: ({ getters, dispatch }, { name }) => {
    const filters = getters.tweetSearchFilterOptions();

    return autoDeletionRules
      .create({ name, active: true, filters })
      .then(() => dispatch("GET_AUTO_DELETION_RULES"));
  },
  UPDATE_AUTO_DELETION_RULE: (
    { getters, dispatch },
    { ruleId, name, active, filters }
  ) => {
    const mergedFilters = {
      ...getters.tweetSearchFilterOptions(),
      ...filters,
    };

    return autoDeletionRules
      .update(ruleId, { name, active, filters: mergedFilters })
      .then(() => dispatch("GET_AUTO_DELETION_RULES"));
  },
  DELETE_AUTO_DELETION_RULE: ({ dispatch }, ruleId) => {
    return autoDeletionRules
      .delete(ruleId)
      .then(() => dispatch("GET_AUTO_DELETION_RULES"));
  },
  GET_USER_FEATURES: ({ commit }) => {
    return getUserFeatures().then((response) => {
      commit("ADD_USER_FEATURES_RAW_RESPONSE", response.data);
    });
  },
  GET_PACKAGES: ({ commit }) => {
    return getPackages().then((response) =>
      commit("SET_PACKAGES", response.data)
    );
  },
  STOP_PACKAGE: async ({ dispatch }, { task } = {}) => {
    return stopPackage(task).then(() => dispatch("GET_PACKAGES"));
  },
  UPDATE_USER_FEATURE_DATA({ state, commit, getters, dispatch }) {
    const userFeatureData = state.userFeatureRawResponses.map(
      getters.processUserFeaturesResponse
    );

    commit("SET_USER_FEATURES", userFeatureData);
    dispatch("UPDATE_FEATURE_LIMIT_POPUP_DATA");

    state.userFeatureUpdateRequired = false;
  },
  UPDATE_FEATURE_LIMIT_POPUP_DATA({ getters, commit }) {
    const popupData = getters.createFeatureLimitPopupData;

    if (popupData) commit("SET_FEATURE_LIMIT_POPUP_DATA", popupData);
  },
  CREATE_UPGRADE_POPUP({ commit, dispatch }, data) {
    // refresh offer data to get correct proration amounts
    dispatch("GET_OFFERS", { forceUpdate: true }).then(() =>
      commit("SET_UPGRADE_POPUP_DATA", data)
    );
  },
  SELECT_SHOWN_TWEETS({ state, getters, commit, dispatch }) {
    dispatch("TOGGLE_ALL_TWEETS_SELECTED", { selected: false });

    state.tweetState
      .filter((tweetState) =>
        getters.tweetsToShow.find((tweet) => tweet.id === tweetState.id)
      )
      .forEach((tweetState) => (tweetState.selected = true));

    state.archiveFavoriteState
      .filter((tweetState) =>
        getters.tweetsToShow.find((tweet) => tweet.id === tweetState.id)
      )
      .forEach((tweet) => (tweet.selected = true));

    commit("SET_TWEET_SELECTION_STATE", "shown");
  },
  TOGGLE_ALL_TWEETS_SELECTED({ state, commit }, { selected }) {
    state.tweetState.forEach((tweet) => (tweet.selected = selected));
    state.archiveFavoriteState.forEach((tweet) => (tweet.selected = selected));

    if (selected) commit("SET_TWEET_SELECTION_STATE", "all");
    else commit("SET_TWEET_SELECTION_STATE", "none");
  },
};
