export default {
  computed: {
    twitterServiceActive() {
      return this.$store.getters.twitterServiceActive;
    },
  },
  methods: {
    checkTwitterStatus() {
      if (
        !this.twitterServiceActive &&
        this.$te(
          "global.error.twitter_service_inactive_title",
          this.$i18n.fallbackLocale
        )
      ) {
        this.$store.commit("ADD_ERROR", {
          error: {
            title: this.$t("global.error.twitter_service_inactive_title"),
            message: this.$t(
              "global.error.twitter_service_inactive_description_html"
            ),
          },
        });
      }
    },
  },
};
