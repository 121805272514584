import SortStates from "../resources/tweetSortStates";
import FeatureNames from "~/resources/featureActionNames";
import { FilterNames } from "~/resources/tweetFilterNames";
import { isNumber } from "~/resources/utilities";

const getOfferFeature = (offers) => (feature) => {
  const featureObj = offers.included.find(
    (item) => item.type === "offerFeature" && item.id === feature.id
  );

  return {
    id: featureObj.id,
    type: featureObj.attributes.featureType,
    action: featureObj.attributes.action,
    showInFeaturePage: featureObj.attributes.showInFeaturePage,
    showInPricing: featureObj.attributes.showInPricing,
    limit: featureObj.attributes.limit,
    monthlyPrice: featureObj.attributes.monthlyFeaturePrice,
    yearlyPrice: featureObj.attributes.yearlyFeaturePrice,
    prorationAmount: parseFloat(featureObj.attributes.prorationAmount),
  };
};

const parseOffer = (offers) => (offer) => ({
  id: offer.id,
  code: offer.attributes.code,
  free: offer.attributes.free,
  recurring: offer.attributes.recurring,
  monthlyPrice: parseFloat(offer.attributes.monthlyPrice),
  yearlyPrice: parseFloat(offer.attributes.yearlyPrice),
  oneTimePrice: parseFloat(offer.attributes.oneTimePrice),
  fullMonthlyPrice: parseFloat(offer.attributes.fullMonthlyPrice),
  fullYearlyPrice: parseFloat(offer.attributes.fullYearlyPrice),
  prorationAmount: parseFloat(offer.attributes.prorationAmount),
  discountAmount: parseFloat(offer.attributes.discountAmount),
  basicMonthlyPrice: parseFloat(offer.attributes.basicMonthlyPrice),
  basicYearlyPrice: parseFloat(offer.attributes.basicYearlyPrice),
  features: offer.relationships.activeOffersFeatures.data.map(
    getOfferFeature(offers)
  ),
});

const planSupportsFeature = (plan, featureAction) => {
  if (!plan || !featureAction) return;

  return plan.features.find((feature) => feature.action === featureAction);
};

const featureLimitForPlan = (featureAction, plan) => {
  const planFeatureData = plan.features.find(
    (feature) => feature.action === featureAction
  );

  return planFeatureData ? planFeatureData.limit : null;
};

export default {
  firstLoadDone(state) {
    return state.firstLoadDone;
  },

  loadingOffers(state) {
    return state.loadingOffers;
  },

  isSiteUnderMaintenance(state) {
    return state.status?.underMaintenance;
  },

  isChatOnline(state) {
    return state.status?.chatOnline;
  },

  currency(state) {
    const { session } = state;

    if (!session) return "";

    return session.currencyTitle;
  },

  currencySymbol(state, getters) {
    if (getters.currency.toUpperCase() === "EUR") return "€";
    else if (getters.currency.toUpperCase() === "USD") return "$";
    else return getters.currency.toUpperCase();
  },

  location(state) {
    const { session } = state;

    if (!session) return "";

    return session.location;
  },

  pricingPlans(state) {
    const { offers } = state;

    if (!offers) return [];

    return offers.data.map(parseOffer(offers));
  },

  pricingPlanById: (state, getters) => (id) => {
    if (!id) return;

    return getters.pricingPlans.find((plan) => plan.id === id);
  },

  pricingPlanByFeatureAction: (state, getters) => (action) => {
    return getters.pricingPlans.find((plan) =>
      planSupportsFeature(plan, action)
    );
  },

  pricingPlanWithHigherLimitForFeature: (state, getters) => (featureAction) => {
    const currentFeatureLimit = getters.userCurrentFeatureLimit(featureAction);

    return getters.pricingPlans.find((plan) => {
      const isPlanHigher = parseInt(plan.id) > parseInt(getters.userPlan.id);
      const isPlanFeatureLimitHigher =
        featureLimitForPlan(featureAction, plan) === null ||
        featureLimitForPlan(featureAction, plan) > currentFeatureLimit;

      return isPlanHigher && isPlanFeatureLimitHigher;
    });
  },

  featureById: (state) => (id) => {
    if (!id) return;

    const feature = state.features?.data.find(
      (feature) => feature.id === id.toString()
    );

    return feature;
  },

  planFeatureById: (state, getters) => (planId, featureId) => {
    const plan = getters.pricingPlanById(planId);

    return plan?.features.find((feature) => feature.id === featureId);
  },

  featureByAction: (state) => (action) => {
    const feature = state.features?.data.find(
      (feature) => feature.attributes.action === action
    );

    return feature;
  },

  planFeatureByAction: (state, getters) => (planId, featureAction) => {
    const plan = getters.pricingPlanById(planId);

    return plan?.features.find((feature) => feature.action === featureAction);
  },
  // this looks at showInFeaturePage only and not at the
  // newer showInPricing property
  showableFeatureActions(state) {
    if (!state.features) return [];

    return state.features.data
      .filter((feature) => feature.attributes.showInFeaturePage)
      .map((feature) => feature.attributes.action);
  },

  userAuthenticated(state) {
    return state.session?.authenticated;
  },

  timeSinceLogin:
    (state, getters) =>
    (currentTime = Date.now()) => {
      if (!getters.user) return Infinity;

      const loginTime = new Date(getters.user.attributes.loggedInAt).getTime();

      return currentTime - loginTime;
    },

  dontShowDeleteConfirmation(state) {
    return state.dontShowDeleteConfirmation;
  },

  user(state) {
    return state.user?.data;
  },

  isUserStale: (state) => (currentTime) => {
    return currentTime - state.userFetchTimestamp > state.userStaleThreshold;
  },

  isSessionAfterLogin(state) {
    return state.isSessionAfterLogin;
  },

  userCurrency(state) {
    return state.session?.currencyTitle;
  },

  userPlan(state, getters) {
    const currentOffer = state.user?.data.relationships.offer.data;

    if (!currentOffer) return;

    return getters.pricingPlanById(currentOffer.id);
  },

  userHasCanceledOrder(state) {
    return state.user?.data.relationships.canceledOrder.data !== null;
  },

  userCanceledOrder(state, getters) {
    if (!getters.userHasCanceledOrder) return;

    const orderId = state.user?.data.relationships.canceledOrder.data.id;
    return state.user?.included.find((obj) => obj.id === orderId);
  },

  userActiveOrder(state) {
    const activeOrderData = state.user?.data.relationships.activeOrder.data;

    if (!activeOrderData) return;

    const activeOrder = state.user.included.find(
      (obj) => obj.id === activeOrderData.id
    );

    if (!activeOrder) return;
    return activeOrder.attributes;
  },

  userHasCancelationDiscount(state, getters) {
    return getters.userActiveOrder
      ? getters.userActiveOrder.cancelDiscountEnabled
      : false;
  },
  cancelationDiscountAvailable(state, getters) {
    return getters.userActiveOrder
      ? getters.userActiveOrder.cancelationDiscountAvailable
      : false;
  },

  userPaymentType(state, getters) {
    return getters.userActiveOrder ? getters.userActiveOrder.paymentType : null;
  },
  userCountry(state) {
    return state.user ? state.user.data.attributes.countryName : null;
  },
  showUpgradePopup(state) {
    return state.upgradePopupData.show;
  },

  upgradePopupData(state) {
    return state.upgradePopupData;
  },

  userFeatureUpdateRequired(state) {
    return state.userFeatureUpdateRequired;
  },

  userFeatureLimitStatuses(state) {
    return state.userFeatureLimitStatuses;
  },

  currentFeatureLimitStatus(state) {
    const length = state.userFeatureLimitStatuses.length;
    if (!length) return;

    return state.userFeatureLimitStatuses[length - 1];
  },

  previousFeatureLimitStatus(state) {
    const length = state.userFeatureLimitStatuses.length;
    if (length < 2) return;

    return state.userFeatureLimitStatuses[length - 2];
  },

  featureLimitJustExceeded: (state, getters) => (featureAction) => {
    if (
      !getters.previousFeatureLimitStatus ||
      !getters.currentFeatureLimitStatus
    )
      return false;

    const wasExceeded = getters.previousFeatureLimitStatus.some(
      (feature) => feature.action === featureAction && feature.isExceeded
    );
    const isExceeded = getters.currentFeatureLimitStatus.some(
      (feature) => feature.action === featureAction && feature.isExceeded
    );

    return !wasExceeded && isExceeded;
  },

  userCurrentFeatureLimit: (state, getters) => (featureAction) => {
    const userPlan = getters.userPlan;

    if (!userPlan) return;

    return featureLimitForPlan(featureAction, userPlan);
  },

  userCurrentFeatureSpend: (state, getters) => (featureAction) => {
    if (!getters.currentFeatureLimitStatus) return;

    const featureStatusData = getters.currentFeatureLimitStatus.find(
      (featureData) => featureData.action === featureAction
    );

    return featureStatusData ? featureStatusData.monthlyExecutedCount : null;
  },

  featureLimitExceeded: (state, getters) => (featureAction) => {
    const featureLimitStatusData = getters.currentFeatureLimitStatus;

    if (
      !featureLimitStatusData ||
      featureLimitStatusData.every(
        (featureData) => featureData.action !== featureAction
      )
    )
      return false;

    const featureData = featureLimitStatusData.find(
      (featureData) => featureData.action === featureAction
    );

    return featureData.isExceeded;
  },

  featureLimitExceededPopupData(state) {
    return state.featureLimitExceededPopupData;
  },

  // Used for keeping feature status history, which is required to trigger
  // popups only on those updates where a feature spend reaches its limit
  processUserFeaturesResponse: (state, getters) => (userFeatures) => {
    return userFeatures.map((featureStatusData) => {
      const featureAction = featureStatusData.attributes.action;
      const featureSpend = featureStatusData.attributes.monthlyExecutedCount;
      const featureLimit = getters.userCurrentFeatureLimit(featureAction);

      return {
        ...featureStatusData.attributes,
        isExceeded:
          isNumber(featureSpend) &&
          isNumber(featureLimit) &&
          featureSpend >= featureLimit,
      };
    });
  },

  createFeatureLimitPopupData(state, getters) {
    if (!getters.currentFeatureLimitStatus) return;

    const featureWithExceededLimitData = getters.currentFeatureLimitStatus.find(
      (featureStatusData) =>
        getters.featureLimitJustExceeded(featureStatusData.action)
    );

    if (!featureWithExceededLimitData) return;

    const planWithHigherLimit = getters.pricingPlanWithHigherLimitForFeature(
      featureWithExceededLimitData.action
    );

    if (!planWithHigherLimit) return;

    return {
      show: true,
      featureAction: featureWithExceededLimitData.action,
      planId: planWithHigherLimit.id,
    };
  },

  userHasAccessToFeature: (state, getters) => (featureAction) => {
    if (!getters.userPlan) return false;

    return (
      typeof getters.userPlan.features.find(
        (feature) => feature.action === featureAction
      ) === "object"
    );
  },

  userHasAccessToFilter: (state, getters) => (filter) => {
    return filter.relatedFeatures.every((featureAction) =>
      getters.userHasAccessToFeature(featureAction)
    );
  },

  userHasAccessToArchiveFavorites(state, getters) {
    const userHasAccessToArchiveUpload = getters.userHasAccessToFeature(
      FeatureNames.get("archive_upload")
    );
    const userHasAccessToUnlikeTweets = getters.userHasAccessToFeature(
      FeatureNames.get("unlike_tweets")
    );
    const userHasArchiveFavorites =
      getters.user?.attributes.archiveFavoritesCount > 0;

    return (
      userHasAccessToArchiveUpload &&
      userHasAccessToUnlikeTweets &&
      userHasArchiveFavorites
    );
  },

  isFilterFeatureLimitExceeded: (state, getters) => (filter) => {
    if (filter && !filter.relatedFeatures.length) return false;

    return filter.relatedFeatures.some((featureAction) =>
      getters.featureLimitExceeded(featureAction)
    );
  },

  planThatSupportsFilter: (state, getters) => (filter) => {
    const neededFeatures = filter.relatedFeatures.filter(
      (featureAction) => !getters.userHasAccessToFeature(featureAction)
    );
    const neededPlans = neededFeatures.map((featureAction) =>
      getters.pricingPlanByFeatureAction(featureAction)
    );

    return neededPlans.length ? neededPlans[0] : {};
  },

  twitterServiceActive(state) {
    return state.status?.twitterAPIStatus;
  },

  uploadedArchiveStatus(state, getters) {
    const user = getters.user;
    const { uploadedArchiveData, archiveDirectUploadProgressValues } = state;

    if (!user) return;

    const uploadedSize = archiveDirectUploadProgressValues.reduce(
      (result, item) => result + item.loaded,
      0
    );
    const totalSize = archiveDirectUploadProgressValues.reduce(
      (result, item) => result + item.total,
      0
    );

    const unzippedArchives = uploadedArchiveData.filter(
      (archiveData) => archiveData.attributes.status !== "pending"
    ).length;
    const loadedTweets = uploadedArchiveData.reduce(
      (result, archiveData) =>
        result + archiveData.attributes.archiveTweetsCount,
      0
    );
    const loadedArchives = uploadedArchiveData.filter(
      (archiveData) =>
        archiveData.attributes.status === "loaded_in" ||
        archiveData.attributes.status === "outdated"
    ).length;

    return {
      error: uploadedArchiveData.some(
        (archive) => archive.attributes.errorCase !== "no_error"
      ),
      uploadedSize,
      totalSize,
      uploading: uploadedSize / totalSize || 0,
      processing: unzippedArchives / uploadedArchiveData.length || 0,
      importing:
        loadedArchives > 0 && loadedArchives === uploadedArchiveData.length
          ? 1
          : Math.min(loadedTweets / user.attributes.statusesCount, 0.95),
    };
  },

  archiveUploading(state) {
    return state.archiveUploading;
  },

  showPreDashboard(state) {
    return state.showPreDashboard;
  },

  preDashboardShown(state) {
    return state.preDashboardShown;
  },

  loadingTweets(state) {
    return state.loadingTweets;
  },

  reloadingTweets(state) {
    return state.reloadingTweets;
  },

  loaderJobsRunning(state) {
    if (!state.user) return false;

    const loaderJobIds = state.user.data.relationships.loaderJobs.data.map(
      (job) => job.id
    );
    const loaderJobs = state.user.included.filter(
      (item) => item.type === "job" && loaderJobIds.includes(item.id)
    );

    return loaderJobs.some(
      (job) => !["finished", "failed"].includes(job.attributes.state)
    );
  },

  fetchTweets(state) {
    const tweetTypeFilter = state.tweetSearchFilters.find(
      (filter) => filter.name === FilterNames.tweet_type
    );

    if (!tweetTypeFilter) return false;

    return (
      tweetTypeFilter.anyApiOptionsActive ||
      (!tweetTypeFilter.anyApiOptionsActive &&
        !tweetTypeFilter.anyNonApiOptionsActive)
    );
  },

  fetchArchiveFavorites(state, getters) {
    const tweetTypeFilter = state.tweetSearchFilters.find(
      (filter) => filter.name === FilterNames.tweet_type
    );

    if (!tweetTypeFilter) return false;

    const archiveFavoritesOption = tweetTypeFilter.getOption("archive_likes");

    return (
      getters.userHasAccessToArchiveFavorites &&
      !getters.usingInvalidFiltersForArchiveFavorites &&
      !getters.loadStoredTweets &&
      (archiveFavoritesOption.selected || !tweetTypeFilter.anyApiOptionsActive)
    );
  },

  allTweetsLoaded(state) {
    return (
      state.tweets?.length === 0 ||
      (state.tweetMetadata &&
        state.tweets?.length >= (state.tweetMetadata.filteredCount ?? 0))
    );
  },

  allArchiveFavoritesLoaded(state) {
    return (
      state.archiveFavorites &&
      state.archiveFavoriteMetadata &&
      state.archiveFavorites.length >=
        state.archiveFavoriteMetadata.filteredArchiveFavoritesCount
    );
  },

  tweets(state) {
    return state.tweets;
  },

  tweetsToShow(state, getters) {
    if (
      !getters.allTweetsLoaded ||
      getters.loadStoredTweets ||
      !getters.fetchArchiveFavorites
    )
      return getters.tweets;
    else return [...getters.tweets, ...getters.archiveFavorites];
  },

  tweetStateToShow(state, getters) {
    if (getters.loadStoredTweets) return getters.tweetState;
    else return [...getters.tweetState, ...getters.archiveFavoriteState];
  },

  loadStoredTweets(state) {
    return state.loadStoredTweets;
  },

  tweetMetadata(state) {
    return state.tweetMetadata;
  },

  tweetState(state) {
    return state.tweetState;
  },

  archiveFavorites(state) {
    return state.archiveFavorites;
  },

  archiveFavoriteMetadata(state) {
    return state.archiveFavoriteMetadata;
  },

  archiveFavoriteState(state) {
    return state.archiveFavoriteState;
  },

  totalTweetCount(state, getters) {
    const tweetCount = state.tweetMetadata?.filteredCount ?? 0;
    const archiveFavoriteCount = getters.fetchArchiveFavorites
      ? state.archiveFavoriteMetadata?.filteredArchiveFavoritesCount ?? 0
      : 0;

    return tweetCount + archiveFavoriteCount;
  },

  totalTweetCountWithoutFavorites(state) {
    const tweetCount = state.tweetMetadata?.filteredCount ?? 0;
    const favoritesCount = state.tweetMetadata?.filteredFacoritesCount ?? 0;

    return tweetCount - favoritesCount;
  },

  findTweetState: (state) => (id, type) => {
    const stateArray =
      type === "tweet" ? state.tweetState : state.archiveFavoriteState;
    const tweetState = stateArray.find((tweet) => tweet.id === id);

    if (!tweetState) return;

    return tweetState;
  },

  tweetSelectionState(state) {
    return state.tweetSelectionState;
  },

  selectedTweetCount(state, getters) {
    switch (state.tweetSelectionState) {
      case "shown":
        return getters.tweetStateToShow.filter((tweet) => tweet.selected)
          .length;
      case "all":
        return getters.totalTweetCount;
      default:
        return 0;
    }
  },
  // when calculating if the user has reached their tweet deletion limit, we
  // don't take selected likes into account
  selectedTweetCountWithoutFavorites(state, getters) {
    switch (state.tweetSelectionState) {
      case "shown":
        return getters.tweetStateToShow.filter((tweet) => {
          const tweetData = getters.tweetsToShow.find(
            (tweetObj) => tweetObj.id === tweet.id
          );
          const tweetIsFavorite =
            tweetData?.type.toLowerCase().includes("favorite") ||
            tweetData?.attributes.favorite;

          return !tweetIsFavorite && tweet.selected;
        }).length;
      case "all":
        return getters.totalTweetCountWithoutFavorites;
      default:
        return 0;
    }
  },

  selectedTweetIds(state) {
    return state.tweetState
      .filter((tweetStateObj) => tweetStateObj.selected)
      .map((tweetStateObj) =>
        state.tweets.find((tweet) => tweet.id === tweetStateObj.id)
      )
      .map((tweet) => tweet.attributes.twitterIdStr);
  },

  selectedArchiveFavoriteIds(state) {
    return state.archiveFavoriteState
      .filter((tweetStateObj) => tweetStateObj.selected)
      .map((tweetStateObj) =>
        state.archiveFavorites.find((tweet) => tweet.id === tweetStateObj.id)
      )
      .map((tweet) => tweet.attributes.twitterIdStr);
  },

  tweetSearchFilters(state) {
    return state.tweetSearchFilters;
  },

  pillFilterNames(state) {
    return state.pillFilterNames;
  },

  usingInvalidFiltersForArchiveFavorites(state) {
    // the tweet type filter is a special case
    const filtersWithoutTweetTypeFilter = state.tweetSearchFilters.filter(
      (filter) => filter.name !== FilterNames.tweet_type
    );

    return filtersWithoutTweetTypeFilter.some(
      (filter) =>
        filter.active &&
        !state.archiveFavoriteAllowedFilterNames.includes(filter.name)
    );
  },

  tweetSearchFilterOptions:
    (state) =>
    (apiOptionsOnly = true) => {
      return state.tweetSearchFilters
        .map((filter) => filter.getQuery(apiOptionsOnly))
        .reduce((result, filterQuery) => ({ ...result, ...filterQuery }), {});
    },

  archiveFavoriteFilterOptions(state) {
    return state.tweetSearchFilters
      .filter((filter) =>
        state.archiveFavoriteAllowedFilterNames.includes(filter.name)
      )
      .map((filter) => filter.getQuery())
      .reduce((result, filterQuery) => ({ ...result, ...filterQuery }), {});
  },

  tweetSortOptions(state) {
    switch (state.tweetSortState) {
      case SortStates.get("newest_first"):
        return { sort_direction: "desc" };
      case SortStates.get("oldest_first"):
        return { sort_direction: "asc" };
      case SortStates.get("most_retweets_first"):
        return { sort_retweet_direction: "desc" };
      case SortStates.get("least_retweets_first"):
        return { sort_retweet_direction: "asc" };
      case SortStates.get("most_likes_first"):
        return { sort_favorite_direction: "desc" };
      case SortStates.get("least_likes_first"):
        return { sort_favorite_direction: "asc" };
    }
  },

  archiveFavoriteSortOptions(state) {
    switch (state.tweetSortState) {
      case SortStates.get("newest_first"):
        return { sort_direction: "desc" };
      case SortStates.get("oldest_first"):
        return { sort_direction: "asc" };
      default:
        return { sort_direction: "desc" };
    }
  },

  filterByType: (state) => (type) => {
    return state.tweetSearchFilters.find((filter) => filter.type === type);
  },

  filterByName: (state) => (name) => {
    return state.tweetSearchFilters.find((filter) => filter.name === name);
  },

  autoDeletionRulesLoaded(state) {
    return state.autoDeletionRulesLoaded;
  },

  autoDeletionRules(state) {
    return state.autoDeletionRules || [];
  },

  packages(state) {
    return state.packages;
  },
};
