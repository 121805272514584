<template>
  <span class="rich-text--container" v-html="cleanHtml" />
</template>

<script setup lang="ts">
import DOMPurify from "isomorphic-dompurify";

const props = defineProps({
  content: {
    type: String,
    required: false,
    default: "",
  },
});

const cleanHtml = ref(
  DOMPurify.sanitize(props.content, {
    USE_PROFILES: { html: true },
  })
);
</script>

<style scoped>
.rich-text--container:deep(div) {
  position: relative;
}

.rich-text--container:deep(div:nth-of-type(1n + 2)) {
  @apply mt-16;
}
.rich-text--container:deep(a) {
  @apply text-blue;
}

.rich-text--container.gray-links:deep(a) {
  @apply text-base-85;
}

.rich-text--container:deep(ul) {
  @apply list-inside list-disc;
}

.rich-text--container:deep(ol) {
  @apply list-inside list-decimal;
}
</style>
