import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public;
  nuxtApp.vueApp.use(
    createGtm({
      id: config.GTM_ID,
      defer: true,
      enabled: config.MODE !== "development",
      vueRouter: useRouter(),
    })
  );
});
