<template>
  <div
    class="w-full max-w-screen-xl mx-auto"
    :class="{ 'md:px-16': !noPadding }"
  >
    <hr class="border-t border-base-85" />
  </div>
</template>

<script>
export default {
  props: {
    noPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
