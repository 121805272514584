import store from "@/store";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    store.commit("ADD_ERROR", {
      error: {
        title: "Application error.",
        message: (error as Error).message,
      },
    });

    nextTick(() => {
      throw error;
    });
  };
});
