import VueGtag from "vue-gtag";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public;
  nuxtApp.vueApp.use(
    VueGtag,
    {
      config: { id: config.GA_ID },
      enabled: config.MODE !== "development",
    },
    useRouter()
  );
});
