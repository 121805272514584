import { get, post, put, del } from "./fetch";

export function getStatus() {
  return get("/api/v1/status");
}

export function getSession() {
  return get("/api/v1/sessions/current");
}

export function getUser() {
  return get("/api/v1/secure/users/current");
}

export function grantDiscount(discountType = "") {
  return post("/api/v1/secure/user_discounts", { discount_type: discountType });
}

export function updateUserCountry({ countryName } = {}) {
  return post("/api/v1/secure/users/update_country_name", {
    country_name: countryName,
  });
}

export function getTranslations(locale, groups) {
  const localeParam = locale ? `locale=${locale}` : "";
  const groupParam = groups ? `group=${groups.join(",")}` : "";

  return get(
    `/api/v1/translations?${groupParam}&${localeParam}`
  );
}

export function getFeatures() {
  return get("/api/v1/features");
}

export function getOffers() {
  return get("/api/v1/offers");
}

export function getBraintreeClientToken() {
  return post("/api/v1/secure/orders/client_token");
}

export function createOrder({
  offerId,
  paymentType,
  locale,
  referral_code,
} = {}) {
  return post("/api/v1/secure/orders", {
    offerId,
    paymentType,
    locale,
    referral_code,
  });
}

export function checkoutOrder({
  orderId,
  paymentMethodNonce,
  countryName,
} = {}) {
  return post(`/api/v1/secure/orders/${orderId}/checkout`, {
    paymentMethodNonce,
    countryName,
  });
}

export function cancelOrder() {
  return post("/api/v1/secure/orders/cancel");
}

export function upgradeOrder({ offerId } = {}) {
  return post("/api/v1/secure/orders/upgrade", { offerId });
}

export function applyCancelationDiscount() {
  return post("/api/v1/secure/orders/add_discount", {
    discount_type: "cancel_subscription",
  });
}

export const orders = {
  create: createOrder,
  checkout: checkoutOrder,
  cancel: cancelOrder,
  upgrade: upgradeOrder,
  getBraintreeClientToken,
  applyCancelationDiscount,
};

export function uploadArchive({ archive_files } = {}) {
  return post("/api/v1/secure/archives", { archive_files });
}

export function getArchiveStatus({ archiveId } = {}) {
  return get(`/api/v1/secure/archives/${archiveId}`);
}

export const archives = {
  upload: uploadArchive,
  status: getArchiveStatus,
};

export function getTweets(options = {}) {
  const urlParams = new URLSearchParams(options).toString();
  return get(`/api/v1/secure/tweets?${urlParams}`);
}

export function deleteTweetsByFilter(options = {}) {
  const urlParams = new URLSearchParams(options).toString();
  return post(`/api/v1/secure/tweets/destroy_by_filter?${urlParams}`);
}

export function deleteTweetsByIds({ tweetIds = [] } = {}) {
  return post("/api/v1/secure/tweets/destroy_by_ids", { tweet_ids: tweetIds });
}

export function getStoredTweets(options = {}) {
  const urlParams = new URLSearchParams(options).toString();
  return get(`/api/v1/secure/stored_tweets?${urlParams}`);
}

export function reloadTwitterTweets() {
  return post("/api/v1/secure/tweets/reload_twitter_tweets");
}

export function reloadTwitterFavorites() {
  return post("/api/v1/secure/favorites/reload_twitter_favorites");
}

export const tweets = {
  get: getTweets,
  getStored: getStoredTweets,
  getArchiveFavorites,
  deleteByFilter: deleteTweetsByFilter,
  deleteByIds: deleteTweetsByIds,
  reloadTwitterTweets,
  reloadTwitterFavorites,
};

export function getArchiveFavorites(options = {}) {
  const urlParams = new URLSearchParams(options).toString();
  return get(`/api/v1/secure/archive_favorites?${urlParams}`);
}

export function deleteArchiveFavoritesByFilter(options = {}) {
  const urlParams = new URLSearchParams(options).toString();
  return post(
    `/api/v1/secure/archive_favorites/destroy_by_filter?${urlParams}`
  );
}

export function deleteArchiveFavoritesByIds({ tweetIds = [] } = {}) {
  return post("/api/v1/secure/archive_favorites/destroy_by_ids", {
    tweet_ids: tweetIds,
  });
}

export const archiveFavorites = {
  get: getArchiveFavorites,
  deleteByFilter: deleteArchiveFavoritesByFilter,
  deleteByIds: deleteArchiveFavoritesByIds,
};

function enableStoredTweets() {
  return post("/api/v1/secure/users/enable_stored_tweets");
}

function disableStoredTweets() {
  return post("/api/v1/secure/users/disable_stored_tweets");
}

export const storedTweets = {
  enable: enableStoredTweets,
  disable: disableStoredTweets,
};

export function getAutoDeletionRules() {
  return get("/api/v1/secure/auto_deletion_rules");
}

export function createAutoDeletionRule({ name, active, filters = {} }) {
  const body = {
    name,
    active,
    filters,
  };

  return post("/api/v1/secure/auto_deletion_rules", body);
}

export function updateAutoDeletionRule(ruleId, { name, active, filters = {} }) {
  const body = {
    name,
    active,
    filters,
  };

  return put(`/api/v1/secure/auto_deletion_rules/${ruleId}`, body);
}

export function deleteAutoDeletionRule(ruleId) {
  return del(`/api/v1/secure/auto_deletion_rules/${ruleId}`);
}

export const autoDeletionRules = {
  get: getAutoDeletionRules,
  create: createAutoDeletionRule,
  update: updateAutoDeletionRule,
  delete: deleteAutoDeletionRule,
};

export function getUserFeatures() {
  return get("/api/v1/secure/users_features");
}

export function getPackages() {
  return get("/api/v1/secure/packages");
}

export function stopPackage(task) {
  return post(`/api/v1/secure/packages/${task.id}/stop`, {
    packageType: task.attributes.packageType,
  });
}

export function createEvent(event_type) {
  const body = {
    event_type,
  };

  return post("/api/v1/secure/events", body);
}

export default {
  getStatus,
  getSession,
  getUser,
  orders,
  getTranslations,
  getFeatures,
  getOffers,
  archives,
  tweets,
  archiveFavorites,
  storedTweets,
  autoDeletionRules,
  getUserFeatures,
  getPackages,
  stopPackage,
  updateUserCountry,
};
