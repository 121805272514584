<template>
  <div>
    <section class="py-16">
      <img
        src="@/assets/img/tweetdeleter-logo.svg"
        alt="Tweetdeleter logo"
        width="96"
        height="48"
      />
    </section>
    <section class="mt-32 md:mt-64 pb-64">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-48">
        <div class="text-left mx-auto lg:pt-32 lg:mx-0">
          <h1 class="hero text-base-30">Update in progress</h1>
          <h1 class="hero">We are working on a brand new webpage.</h1>
          <p class="text--intro mt-32">
            Come back soon and enjoy our new features.
          </p>
        </div>
        <div class="flex flex-col items-center">
          <img
            src="@/assets/img/website/skull-sweaty.svg"
            alt="Page not found illustration"
            width="432"
            height="432"
          />
        </div>
      </div>
    </section>
  </div>
</template>
