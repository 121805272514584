export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

export function isNumber(value) {
  return typeof value === "number" && !isNaN(value);
}

export function bytesToSizeString(bytes, decimals = 1) {
  if (bytes === 0) return "0 Bytes";

  const k = 1000;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const size = bytes / Math.pow(k, i);
  const roundedSize =
    Math.floor(size * Math.pow(10, decimals)) / Math.pow(10, decimals);
  const unit = sizes[i];

  return `${roundedSize.toFixed(decimals)} ${unit}`;
}

export function isHttps() {
  return document.location.protocol == "https:";
}

export default {
  isString,
  isNumber,
  bytesToSizeString,
  isHttps,
};
