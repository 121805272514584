import type { RouterConfig } from "@nuxt/schema";

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  scrollBehavior(to: any, from: any, savedPosition: any) {
    if (savedPosition) return savedPosition;

    if (to.hash) {
      const el = document.querySelector(to.hash);
      const margin = 32;

      if (el) return { top: el.offsetTop - margin, behavior: "smooth" };
    }

    return {
      top: 0,
      left: 0,
    };
  },
};
