export default class {
  constructor(names = {}) {
    this.names = names;
  }

  get(name) {
    if (name in this.names) return this.names[name];
    else throw new Error(`Requested name (${name}) is not defined`);
  }

  values() {
    return Object.values(this.names);
  }
}
