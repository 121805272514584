export default {
  SET_STATUS: (state, { status }) => {
    state.status = status.data.attributes;
  },
  SET_SESSION: (state, { session }) => {
    state.session = session.data.attributes;
  },
  SET_USER: (state, { user }) => {
    state.user = user;
  },
  SET_FEATURES: (state, { features }) => {
    state.features = features;
  },
  SET_OFFERS: (state, { offers }) => {
    state.offers = offers;
  },
  ADD_ERROR: (state, { error }) => {
    state.errors.push(error);
  },
  DISMISS_ERROR: (state, { errorIndex }) => {
    state.errors.splice(errorIndex, 1);
  },
  SET_UPLOADED_ARCHIVE_DATA: (state, { archiveData }) => {
    state.uploadedArchiveData = archiveData;
  },
  ADD_UPLOADED_ARCHIVE_DATA: (state, { archiveData }) => {
    state.uploadedArchiveData.push(archiveData);
  },
  RESET_UPLOADED_ARCHIVE_DATA: (state) => {
    state.uploadedArchiveData = [];
    state.archiveUploading = false;
    state.archiveDirectUploadProgressValues = [];

    state.lastTweetFetchOptionsString = ""; // ensure tweets are fetched on next GET_TWEETS action call
  },
  SET_ARCHIVE_DIRECT_UPLOAD_PROGRESS: (state, data) => {
    const existingData = state.archiveDirectUploadProgressValues.find(
      (item) => item.file === data.file
    );

    if (existingData) {
      existingData.loaded = data.loaded;
    } else state.archiveDirectUploadProgressValues.push(data);
  },
  SET_ARCHIVE_UPLOADING_STATE: (state, { isUploading }) => {
    state.archiveUploading = isUploading;
  },
  SET_UPLOAD_ARCHIVE_CHECKOUT_FLOW_STATE(state, value) {
    state.uploadArchiveCheckoutFlowStarted = value;
  },
  GRANT_COOKIE_CONSENT(state) {
    state.cookieConsentGranted = true;
  },
  SET_PRE_DASHBOARD_SHOWN(state, { shown }) {
    state.preDashboardShown = shown;
  },
  SET_PRE_DASHBOARD_STATE(state, { show }) {
    state.showPreDashboard = show;

    if (show) state.preDashboardShown = true;
  },
  SET_TWEET_SELECTION_STATE(state, selectionState) {
    state.tweetSelectionState = selectionState;
  },
  SET_TWEET_DATA(state, { metadata = {}, tweets = [] }) {
    state.tweetState = tweets.map((tweet) => ({
      id: tweet.id,
      selected: false,
    }));
    state.tweetMetadata = metadata;
    state.tweets = tweets;
  },
  SET_ARCHIVE_FAVORITES_DATA(state, { metadata = {}, tweets = [] }) {
    state.archiveFavoriteState = tweets.map((tweet) => ({
      id: tweet.id,
      selected: false,
    }));
    state.archiveFavoriteMetadata = metadata;
    state.archiveFavorites = tweets;
  },
  SET_LOAD_STORED_TWEETS(state, loadStored) {
    if (state.loadStoredTweets !== loadStored)
      state.lastTweetFetchOptionsString = ""; // ensure tweets are fetched on next GET_TWEETS action call

    state.loadStoredTweets = loadStored;
  },
  SET_TWEET_SEARCH_FILTERS(state, filterSettings) {
    state.tweetSearchFilters = filterSettings.slice();
  },
  SET_TWEET_SORT_STATE(state, sortState) {
    state.tweetSortState = sortState;
  },
  TRIGGER_SEARCH_FILTER_UPDATE(state) {
    state.tweetSearchFilters = state.tweetSearchFilters.slice();
  },
  SET_AUTO_DELETION_RULES(state, rules) {
    state.autoDeletionRules = rules;
  },
  ADD_USER_FEATURES_RAW_RESPONSE(state, data) {
    state.userFeatureRawResponses = [...state.userFeatureRawResponses, data];
    state.userFeatureUpdateRequired = true;
  },
  SET_USER_FEATURES(state, userFeatureStatuses) {
    state.userFeatureLimitStatuses = userFeatureStatuses;
  },
  SET_PACKAGES(state, packages) {
    state.packages = packages;
  },
  SET_SESSION_AFTER_LOGIN(state, isSessionAfterLogin) {
    state.isSessionAfterLogin = isSessionAfterLogin;
  },
  SET_UPGRADE_POPUP_DATA(state, data = {}) {
    state.upgradePopupData = { ...data };
  },
  HIDE_UPGRADE_POPUP(state) {
    state.upgradePopupData.show = false;
  },
  SET_FEATURE_LIMIT_POPUP_DATA(state, data = { show: false }) {
    state.featureLimitExceededPopupData = { ...data };
  },
  SET_DONT_SHOW_DELETE_CONFIRMATION(state, value) {
    state.dontShowDeleteConfirmation = value;

    window.localStorage.setItem("dontShowDeleteConfirmation", value.toString());
  },
  SET_FIRST_LOAD_DONE(state, value) {
    state.firstLoadDone = value;
  },
};
