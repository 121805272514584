import { fetchTranslations } from "~/resources/pageResourceHelper";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook(
    "i18n:beforeLocaleSwitch",
    async ({ newLocale, context }: any) => {
      await fetchTranslations(context.$router.currentRoute.value, newLocale);
    }
  );
});
