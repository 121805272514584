import NameCollection from "./NameCollection";

const featureActionNames = Object.freeze({
  archive_upload: "archive_upload",
  keyword_search: "keyword_search",
  media_search: "media_search",
  date_search: "date_search",
  type_search: "type_search",
  has_rude_word: "has_rude_word",
  delete_tweets: "delete_tweets",
  delete_all_tweets: "delete_all_tweets",
  unlike_all_tweets: "unlike_all_tweets",
  deleted_tweets_store: "deleted_tweets_store",
  auto_delete: "auto_delete",
  auto_unlike: "auto_unlike",
  day_of_week_search: "day_of_week_search",
  daytime_search: "daytime_search",
  unlike_tweets: "unlike_tweets",
  tweets_loader: "tweets_loader",
  favorites_loader: "favorites_loader",
});

export default new NameCollection(featureActionNames);
