export const FilterNames = Object.freeze({
  tweet_type: "tweet_type",
  date: "date",
  profanities: "profanities",
  time: "time",
  weekday: "weekday",
  media: "media",
  keyword: "keyword",
  offset: "offset",
  older_than: "older_than",
});

export default {
  names: FilterNames,
};
