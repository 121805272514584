import type { RouteLocationNormalized, RouteMeta } from "vue-router";
import store from "../store";
import { getTranslations } from "~/api";
import { mapValues, isObject } from "lodash";

interface RouteMetaWithResources extends RouteMeta {
  resources?: string[];
  contentGroups?: string[];
}

export const fetchResources = async (to: RouteLocationNormalized) => {
  const routeMeta = to.meta as RouteMetaWithResources;
  const promises = [
    store.dispatch("GET_STATUS"),
    store.dispatch("GET_SESSION"),
  ];

  if (routeMeta.resources?.includes("features")) {
    promises.push(store.dispatch("GET_FEATURES"));
  }
  if (routeMeta.resources?.includes("offers")) {
    promises.push(store.dispatch("GET_OFFERS"));
  }

  await Promise.all(promises);
};

export const fetchTranslations = async (
  to: RouteLocationNormalized,
  locale: string = ""
) => {
  const { $i18n } = useNuxtApp();
  const routeMeta = to.meta as RouteMetaWithResources;
  const contentGroups = routeMeta.contentGroups || [];
  const loc = locale ? `en,${locale}` : $i18n.locale.value;

  const messages = await getTranslations(loc, ["global", ...contentGroups]);

  Object.entries(escapeSpecialCharacters(messages)).forEach(
    ([locale, messages]) => {
      $i18n.mergeLocaleMessage(locale, messages);
    }
  );
};

const escapeSpecialCharacters = (messages: Record<string, unknown>) => {
  return mapValuesDeep(messages, (v: string | Record<string, unknown>) => {
    return typeof v === "string"
      ? v.replace(/[@|]/g, (matched: string) => `{'${matched}'}`) // https://vue-i18n.intlify.dev/guide/essentials/syntax#literal-interpolation
      : v;
  });
};

const mapValuesDeep = (
  v: string | Record<string, unknown>,
  callback: (
    v: string | Record<string, unknown>
  ) => string | Record<string, unknown>
) =>
  isObject(v) && !Array.isArray(v)
    ? mapValues(
        v,
        (
          v: string | Record<string, unknown>
        ): string | Record<string, unknown> => mapValuesDeep(v, callback)
      )
    : callback(v);
